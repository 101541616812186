import React, { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import { Group, Image, Menu, UnstyledButton } from '@mantine/core';
import classes from './Language.module.css';
import Eng from '../../images/Eng.png'
import Uz from '../../images/uzb.png'
import {useTranslation} from "react-i18next";

const data = [
    { lang:'en', label: 'English', image: Eng },
    { lang:'uz', label: 'Uzbek', image: Uz },
];

export function LanguagePicker() {
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(data[0]);
    const { i18n } = useTranslation();

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const items = data.map((item) => (
        <Menu.Item
            leftSection={<Image src={item.image} width={18} height={18} />}
            onClick={() => {
                setSelected(item)
                changeLanguage(item.lang)
            }}
            key={item.label}
        >
            {item.label}
        </Menu.Item>
    ));

    return (
        <Menu
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            radius="md"
            width="target"
            withinPortal
        >
            <Menu.Target>
                <UnstyledButton className={classes.control} data-expanded={opened || undefined}>
                    <Group gap="xs">
                        <Image src={selected.image} width={22} height={22}/>
                        <span className={classes.label}>{selected.label}</span>
                    </Group>
                    <IconChevronDown size={16} className={classes.icon} stroke={1.5}/>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{items}</Menu.Dropdown>
        </Menu>
    );
}