import React from 'react';
import {useTranslation} from 'react-i18next';
import { FaTelegram } from "react-icons/fa";
import {HeaderMenu} from "./components/Header/Navbar";
import '@mantine/core/styles.css';
import './App.css';
import {FooterLinks} from "./components/Footer/Footer";

function App() {
    const {t}=useTranslation()

  return (
      <div className="App">
          <HeaderMenu/>
          {t('hello')} I`m Sadriddin Mamatov
          <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: 'center'
          }}>
              This is my Telegram: <a href="https://t.me/Developer_1005">
              <FaTelegram style={{color: '#4ed0d8', fontSize: '26px', marginLeft: '1rem'}}/>
          </a>
          </div>
          <h1>{t('welcome')}</h1>
          <FooterLinks/>
      </div>
  );
}

export default App;

