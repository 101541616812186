import React from "react";
import { IconChevronDown } from '@tabler/icons-react';
import { Burger, Center, Container, Group, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MdOutlineWbSunny } from "react-icons/md";
import classes from './HeaderMenu.module.css';
import {LanguagePicker} from "../Locale/Language";
import {useTheme} from "../../context/themeContext";
import {useTranslation} from "react-i18next";

const links = [
    { link: '/about', label: 'Features' },
    {
        link: '#1',
        label: 'Learn',
        links: [
            { link: '/docs', label: 'Documentation' },
            { link: '/resources', label: 'Resources' },
            { link: '/community', label: 'Community' },
            { link: '/blog', label: 'Blog' },
        ],
    },
    { link: '/about', label: 'About' },
    { link: '/pricing', label: 'Pricing' },
];

export function HeaderMenu() {
    const [opened, { toggle }] = useDisclosure(false);
    const { theme, toggleTheme } = useTheme();
    const {t}=useTranslation();


    const items = links.map((link) => {
        const menuItems = link.links?.map((item) => (
            <Menu.Item key={item.link}>{item.label}</Menu.Item>
        ));

        if (menuItems) {
            return (
                <Menu key={link.label} trigger="hover" transitionProps={{exitDuration: 0}} withinPortal>
                    <Menu.Target>
                        <a
                            href={link.link}
                            className={classes.link}
                            onClick={(event) => event.preventDefault()}
                        >
                            <Center>
                                <span className={classes.linkLabel}>{link.label}</span>
                                <IconChevronDown size={14} stroke={1.5}/>
                            </Center>
                        </a>
                    </Menu.Target>
                    <Menu.Dropdown>{menuItems}</Menu.Dropdown>
                </Menu>
            );
        }

        return (
            <a
                key={link.label}
                href={link.link}
                className={classes.link}
                onClick={(event) => event.preventDefault()}
            >
                {link.label}
            </a>
        );
    });

    return (
        <header className={classes.header}>
            <Container size="md">
                <div className={classes.inner}>
                    <div className={classes.navLogo}>
                        <MdOutlineWbSunny/>
                        Sadriddin

                    </div>
                    <Group gap={5} visibleFrom="sm">
                        {items}
                    </Group>
                    <LanguagePicker/>
                    <button onClick={toggleTheme}>
                        {theme === 'light' ? t('dark') : t('light')}
                    </button>
                    <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm"/>
                </div>
            </Container>
        </header>
    );
}