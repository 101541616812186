const en = {
    translation: {
        welcome: "Welcome",
        hello: "Hello",
        theme: "Theme",
        light: "Light",
        dark: "Dark",
    },
};

export default en;
