const uz = {
    translation: {
        welcome: "Hush kelibsiz",
        hello: "Assalomu aleykum",
        theme: "Tema",
        light: "Kunduz",
        dark: "Tun",
    },
};

export default uz;
