import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en';
import uz from './locales/uz';

i18n
    .use(LanguageDetector) // Detect user's language
    .use(initReactI18next) // Pass to react-i18next
    .init({
        resources: {
            en,
            uz,
        },
        fallbackLng: 'en', // Default language
        interpolation: { escapeValue: false }, // React already escapes values
    });

export default i18n;
