import React from 'react';
import {createRoot} from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';
import {ThemeProvider} from './context/themeContext';
import i18n from './i18n';
import './index.css';
import App from './App';
import {MantineProvider} from "@mantine/core";

const root = createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <MantineProvider>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider>
                    <App/>
                </ThemeProvider>
            </I18nextProvider>
        </MantineProvider>
    </React.StrictMode>
);

